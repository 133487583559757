






import { Component, Vue } from "vue-property-decorator";
import AdminUserCompanyTable from "@/components/admin/user/company/AdminUserCompanyTable.vue";

/**
 * AdminUserCompanyManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminUserCompanyTable,
    },
})
export default class AdminUserCompanyManagement extends Vue {}
