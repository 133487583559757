

















import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { User } from "@/types/user";
import { Company } from "@/types/user/company";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminUserCompanyFormFields from "./AdminUserCompanyFormFields.vue";

/**
 * AdminUserCompanyTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminUserCompanyFormFields,
    },
})
export default class AdminUserCompanyTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private userCompanies: Company[] = [];
    private users: User[] = [];

    private defaultUserCompanyItem: Company = {
        id: -1,
        name: "",
        taxNumber: "",
        addresses: [],
        employees: [],
        responsibleId: -1,
        discount: 0,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.name").toString(),
                value: "name",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            [this.userCompanies, this.users] = await Promise.all([
                this.fetchUserCompanies(),
                this.fetchUsers(),
            ]);
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all user companies
     *
     * @returns Promise<Company[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchUserCompanies(): Promise<Company[]> {
        const response = (
            await axios.get<APIResponse<Company[]>>("/admin/users/companies")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }

    /**
     * Fetches all users
     *
     * @returns Promise<User[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchUsers(): Promise<User[]> {
        const response = (await axios.get<APIResponse<User[]>>("/admin/user"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
